import React from 'react'
import { useTranslation } from 'react-i18next'

const MeetTheTeam = () => {
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'

  return (
    <section
      className={`bg-gray-50 py-8 md:py-16 ${
        isArabic ? 'text-right' : 'text-left'
      }`}
    >
      <div className="container mx-auto px-4">
        <div className="text-center mb-8 md:mb-16">
          <h1
            className={`text-4xl md:text-5xl font-extrabold text-gray-900 leading-tight mb-2 relative ${
              isArabic ? 'arabic-font' : 'english-font'
            }`}
          >
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-green-700 to-green-400">
              {t('teamPage.MeetTheTeam')}
            </span>
            <span className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-gradient-to-r from-green-700 to-green-400"></span>
          </h1>
        </div>

        <div
          className={`bg-white shadow-lg rounded-lg p-8 mb-8 flex flex-col md:flex-row items-center ${
            isArabic ? 'text-right' : 'text-left'
          }`}
        >
          <div className={`md:w-1/2 ${isArabic ? 'md:pl-8' : 'md:pr-8'}`}>
            <img
              src="img/about1.jpg"
              alt={isArabic ? 'فريق العمل' : 'Team'}
              className="w-full rounded-lg shadow-lg mb-8 md:mb-0"
            />
          </div>
          <div className="md:w-1/2 mb-4">
            <p
              className={`text-lg text-center mb-8 ${
                isArabic ? 'arabic-font' : 'english-font'
              }`}
            >
              {t('teamPage.TeamDescription')}
            </p>
            <ul
              className={`text-lg text-center ${
                isArabic ? 'arabic-font' : 'english-font'
              }`}
            >
              <li className="mb-2">{t('teamPage.TeamMember1')}</li>
              <li className="mb-2">{t('teamPage.TeamMember2')}</li>
              <li className="mb-2">{t('teamPage.TeamMember3')}</li>
              <li className="mb-2">{t('teamPage.TeamMember4')}</li>
              <li className="mb-2">{t('teamPage.TeamMember5')}</li>
              <li className="mb-2">{t('teamPage.TeamMember6')}</li>
              <li className="mb-2">{t('teamPage.TeamMember7')}</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MeetTheTeam
