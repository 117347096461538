import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'

const Footer = () => {
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'

  return (
    <footer
      className={`bg-gray-800 py-6 ${
        isArabic ? 'text-right arabic-font' : 'text-left english-font'
      }`}
    >
      <div className="container mx-auto px-4 text-center text-white">
        <div className="flex justify-center mb-4">
          <a
            href="https://www.facebook.com/msjonanurseryqatar/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-blue-400 mx-3"
          >
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>
          <a
            href="https://www.instagram.com/msjona_nursery/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-violet-600 mx-3"
          >
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
          <a
            href="https://www.linkedin.com/in/msjona-nursery-88748a12a/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-sky-500 mx-3"
          >
            <FontAwesomeIcon icon={faLinkedin} size="2x" />
          </a>
          <a
            href="http://www.youtube.com/@msjonanursery5259"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-red-500 mx-3"
          >
            <FontAwesomeIcon icon={faYoutube} size="2x" />
          </a>
        </div>
        <p dangerouslySetInnerHTML={{ __html: t('footerCopyright') }} />
      </div>
    </footer>
  )
}

export default Footer
