import React from 'react'
import { useTranslation } from 'react-i18next'

const ContactUs = () => {
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'

  return (
    <section
      className={`bg-gray-50 py-8 md:py-16 ${
        isArabic ? 'text-right arabic-font' : 'text-left english-font'
      }`}
    >
      <div className="container mx-auto px-4">
        <div className="text-center mb-8 md:mb-16">
          <h1
            className={`text-4xl md:text-5xl font-extrabold text-gray-900 leading-tight mb-2 relative ${
              isArabic ? 'font-arabic' : 'font-english'
            }`}
          >
            <span
              className={`bg-clip-text text-transparent bg-gradient-to-r from-green-700 to-green-400 ${
                isArabic ? 'font-arabic' : 'font-english'
              }`}
            >
              {t('contact.contactUsTitle')}
            </span>
            <span className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-gradient-to-r from-green-700 to-green-400"></span>
          </h1>
          <p
            className={`text-xl font-bold font-sans text-black my-10 ${
              isArabic ? 'font-arabic' : 'font-english'
            }`}
          >
            {t('contact.contactUsDescription')}
          </p>
        </div>

        <form
          action="https://formsubmit.co/msjona15@gmail.com"
          method="POST"
          className="max-w-lg mx-auto"
        >
          <div className="mb-4">
            <label
              className={`block text-lg font-sans mb-2 ${
                isArabic ? 'font-arabic' : 'font-english'
              }`}
              htmlFor="name"
            >
              {t('contact.name')}
            </label>
            <input
              type="text"
              id="name"
              name="name"
              required
              className="w-full px-3 py-2 border-2 border-green-500 rounded-md focus:outline-none focus:ring-2 focus:ring-green-600"
            />
          </div>
          <div className="mb-4">
            <label
              className={`block text-lg font-sans mb-2 ${
                isArabic ? 'font-arabic' : 'font-english'
              }`}
              htmlFor="email"
            >
              {t('contact.email')}
            </label>
            <input
              type="email"
              id="email"
              name="email"
              required
              className="w-full px-3 py-2 border-2 border-green-500 rounded-md focus:outline-none focus:ring-2 focus:ring-green-600"
            />
          </div>
          <div className="mb-4">
            <label
              className={`block text-lg font-sans mb-2 ${
                isArabic ? 'font-arabic' : 'font-english'
              }`}
              htmlFor="message"
            >
              {t('contact.message')}
            </label>
            <textarea
              id="message"
              name="message"
              rows="4"
              required
              className="w-full px-3 py-2 border-2 border-green-500 rounded-md focus:outline-none focus:ring-2 focus:ring-green-600"
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full bg-green-500 text-white font-bold py-2 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400"
          >
            {t('contact.send')}
          </button>
        </form>

        <div className="mt-16 text-center bg-white mx-8 py-4 shadow rounded">
          <p
            className={`text-lg mb-4 px-2 ${
              isArabic ? 'font-arabic' : 'font-english'
            }`}
          >
            {t('contact.visitUs')}
            <p
              className={`text-lg mb-4 ${
                isArabic ? 'font-arabic' : 'font-english'
              }`}
            >
              {t('contact.mailUs')}{' '}
              <a href="mailto:info@msjonanursery.com" className="text-blue-500">
                info@msjonanursery.com
              </a>{' '}
              {t('contact.or')}{' '}
              <a href="mailto:msjona15@gmail.com" className="text-blue-500">
                msjona15@gmail.com
              </a>
            </p>
          </p>
        </div>
        <div className="md:flex mt-4 md:mt-16 text-center">
          <p
            className={`text-lg mb-4 md:w-1/2 bg-white mx-8 py-4 shadow rounded ${
              isArabic ? 'font-arabic' : 'font-english'
            }`}
          >
            <strong>{t('contact.nurseryName')}</strong>
            <br />
            {t('contact.address')}
            <p
              className={`text-lg mb-4 ${
                isArabic ? 'font-arabic' : 'font-english'
              }`}
            >
              {t('contact.phoneOffice')} {t('contact.phoneMobile')}
            </p>
          </p>
          <p
            className={`text-lg mb-4 md:w-1/2 bg-white mx-8 py-4 shadow rounded ${
              isArabic ? 'font-arabic' : 'font-english'
            }`}
          >
            <strong>{t('contact.hours')}</strong>
            <br />
            {t('contact.hoursSundayThursday')}
            <br />
            {t('contact.hoursFridaySaturday')}
          </p>
        </div>
        <div className="flex justify-center my-8">
          <iframe
            title="MS JONA Nursery"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3605.413428263457!2d51.479339!3d25.357457!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45dc7771ceb87d%3A0x88607032936ca656!2sMs%20Jona%20Nursery!5e0!3m2!1sen!2sdz!4v1719946817066!5m2!1sen!2sdz"
            width="800"
            height="400"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </section>
  )
}

export default ContactUs
