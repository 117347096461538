import React from 'react'
import { FaDownload } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

const Admissions = () => {
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'

  return (
    <section
      className={`bg-gray-50 py-8 md:py-16 ${
        isArabic ? 'text-right' : 'text-left'
      }`}
    >
      <div className="container mx-auto px-4">
        <div className="text-center mb-8 md:mb-16">
          <h1
            className={`text-4xl md:text-5xl font-extrabold text-gray-900 leading-tight mb-2 relative ${
              isArabic ? 'arabic-font' : 'english-font'
            }`}
          >
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-green-700 to-green-400">
              {t('admissionsPage.Admissions')}
            </span>
            <span className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-gradient-to-r from-green-700 to-green-400"></span>
          </h1>
        </div>

        <div
          className={`bg-white shadow-lg rounded-lg p-8 mb-8 flex flex-col md:flex-row-reverse items-center ${
            isArabic ? 'text-right' : 'text-left'
          }`}
        >
          <div className={`md:w-1/2 ${isArabic ? 'md:pr-8' : 'md:pl-8'}`}>
            <h2
              className={`text-2xl md:text-3xl font-semibold font-serif text-gray-900 mb-4 ${
                isArabic ? 'arabic-font' : 'english-font'
              }`}
            >
              {t('admissionsPage.AdmissionAvailability')}
            </h2>
            <p
              className={`text-lg text-gray-700 mb-6 ${
                isArabic ? 'arabic-font' : 'english-font'
              }`}
            >
              {t('admissionsPage.AdmissionAvailabilityDescription')}
            </p>
          </div>
          <img
            src="img/a1.jpg"
            alt={
              isArabic
                ? 'قبول - حضانة MS جونا'
                : 'MS JONA Nursery - Qatar - Admission'
            }
            className="w-full md:w-1/2 rounded-lg shadow-md mb-4 md:mb-0"
          />
        </div>

        <div
          className={`bg-white shadow-lg rounded-lg p-8 mb-8 flex flex-col md:flex-row items-center ${
            isArabic ? 'text-right' : 'text-left'
          }`}
        >
          <div className={`md:w-1/2 ${isArabic ? 'md:pr-8' : 'md:pl-8'}`}>
            <h2
              className={`text-2xl md:text-3xl font-semibold font-serif text-gray-900 mb-4 ${
                isArabic ? 'arabic-font' : 'english-font'
              }`}
            >
              {t('admissionsPage.Uniform')}
            </h2>
            <p
              className={`text-lg text-gray-700 mb-6 ${
                isArabic ? 'arabic-font' : 'english-font'
              }`}
            >
              {t('admissionsPage.UniformDescription')}
            </p>
          </div>
          <img
            src="img/a2.jpg"
            alt={
              isArabic
                ? 'زي - حضانة MS جونا'
                : 'MS JONA Nursery - Qatar - Uniform'
            }
            className="w-full md:w-1/2 rounded-lg shadow-md mb-4 md:mb-0"
          />
        </div>

        <div
          className={`bg-white shadow-lg rounded-lg p-8 mb-8 flex flex-col md:flex-row-reverse items-center ${
            isArabic ? 'text-right' : 'text-left'
          }`}
        >
          <div className={`md:w-1/2 ${isArabic ? 'md:pr-8' : 'md:pl-8'}`}>
            <h2
              className={`text-2xl md:text-3xl font-semibold font-serif text-gray-900 mb-4 ${
                isArabic ? 'arabic-font' : 'english-font'
              }`}
            >
              {t('admissionsPage.Books')}
            </h2>
            <p
              className={`text-lg text-gray-700 mb-6 ${
                isArabic ? 'arabic-font' : 'english-font'
              }`}
            >
              {t('admissionsPage.BooksDescription')}
            </p>
          </div>
          <img
            src="img/a3.jpg"
            alt={
              isArabic
                ? 'كتب - حضانة MS جونا'
                : 'MS JONA Nursery - Qatar - Books'
            }
            className="w-full md:w-1/2 rounded-lg shadow-md mb-4 md:mb-0"
          />
        </div>

        <div
          className={`bg-white shadow-lg rounded-lg p-8 mb-8 flex flex-col md:flex-row items-center ${
            isArabic ? 'text-right' : 'text-left'
          }`}
        >
          <div className={`md:w-1/2 ${isArabic ? 'md:pr-8' : 'md:pl-8'}`}>
            <h2
              className={`text-2xl md:text-3xl font-semibold font-serif text-gray-900 mb-4 ${
                isArabic ? 'arabic-font' : 'english-font'
              }`}
            >
              {t('admissionsPage.Calendar')}
            </h2>
            <p
              className={`text-lg text-gray-700 mb-6 ${
                isArabic ? 'arabic-font' : 'english-font'
              }`}
            >
              {t('admissionsPage.CalendarDescription')}
            </p>
            <ul
              className={`list-disc list-inside text-lg text-gray-700 mb-6 space-y-1 ${
                isArabic ? 'arabic-font' : 'english-font'
              }`}
            >
              <li>{t('admissionsPage.EidAlFitr')}</li>
              <li>{t('admissionsPage.EidAlAdha')}</li>
              <li>{t('admissionsPage.NationalDay')}</li>
              <li>{t('admissionsPage.SportsDay')}</li>
            </ul>
          </div>
          <img
            src="img/a4.jpg"
            alt={
              isArabic
                ? 'تقويم - حضانة MS جونا'
                : 'MS JONA Nursery - Qatar - Calendar'
            }
            className="w-full md:w-1/2 rounded-lg shadow-md mb-4 md:mb-0"
          />
        </div>

        <div
          className={`bg-white shadow-lg rounded-lg p-8 mb-8 flex flex-col md:flex-row-reverse items-center ${
            isArabic ? 'text-right' : 'text-left'
          }`}
        >
          <div className={`md:w-1/2 ${isArabic ? 'md:pr-8' : 'md:pl-8'}`}>
            <h2
              className={`text-2xl md:text-3xl font-semibold font-serif text-gray-900 mb-4 ${
                isArabic ? 'arabic-font' : 'english-font'
              }`}
            >
              {t('admissionsPage.ExtraActivities')}
            </h2>
            <p
              className={`text-lg text-gray-700 mb-6 ${
                isArabic ? 'arabic-font' : 'english-font'
              }`}
            >
              {t('admissionsPage.ExtraActivitiesDescription')}
            </p>
            <ul
              className={`list-disc list-inside text-lg text-gray-700 mb-6 grid grid-cols-2 gap-4 ${
                isArabic ? 'arabic-font' : 'english-font'
              }`}
            >
              <li>{t('admissionsPage.Quran')}</li>
              <li>{t('admissionsPage.IslamicStudies')}</li>
              <li>{t('admissionsPage.PhonicsClub')}</li>
              <li>{t('admissionsPage.MarkMaking')}</li>
              <li>{t('admissionsPage.Writing')}</li>
              <li>{t('admissionsPage.Drama')}</li>
              <li>{t('admissionsPage.Coloring')}</li>
              <li>{t('admissionsPage.HandCrafts')}</li>
              <li>{t('admissionsPage.Sports')}</li>
              <li>{t('admissionsPage.BallSkillsClub')}</li>
              <li>{t('admissionsPage.Cooking')}</li>
              <li>{t('admissionsPage.WaterAndSandGames')}</li>
              <li>{t('admissionsPage.Rhymes')}</li>
              <li>{t('admissionsPage.BehaviorAssessment')}</li>
              <li>{t('admissionsPage.Tutoring')}</li>
              <li>{t('admissionsPage.YogaClub')}</li>
              <li>{t('admissionsPage.MusicAndMovement')}</li>
              <li>{t('admissionsPage.AfternoonClub')}</li>
            </ul>
          </div>
          <img
            src="img/a5.jpg"
            alt={
              isArabic
                ? 'أنشطة - حضانة MS جونا'
                : 'MS JONA Nursery - Qatar - Activities'
            }
            className="w-full md:w-1/2 rounded-lg shadow-md mb-4 md:mb-0"
          />
        </div>

        <div
          className={`bg-white shadow-lg rounded-lg p-8 mb-8 flex flex-col items-center ${
            isArabic ? 'text-right' : 'text-left'
          }`}
        >
          <h2
            className={`text-2xl md:text-3xl font-semibold font-serif text-gray-900 mb-4 ${
              isArabic ? 'arabic-font' : 'english-font'
            }`}
          >
            {t('admissionsPage.ApplicationForm')}
          </h2>
          <h3 className="text-green-500 text-5xl font-extrabold py-8 transition-transform duration-500 hover:scale-110 animate-bounce">
            {t('admissionsPage.comingSoon')}
          </h3>
          {/*<p
            className={`text-lg text-gray-700 mb-6 text-center ${
              isArabic ? 'arabic-font' : 'english-font'
            }`}
          >
            {t('admissionsPage.ApplicationFormDescription')}
            <a href="mailto:info@msjonanursery.com" className="text-blue-500">
              info@msjonanursery.com
            </a>{' '}
            {t('admissionsPage.Or')}
            <a href="mailto:msjona15@gmail.com" className="text-blue-500">
              msjona15@gmail.com
            </a>
            .
          </p>
          <a
            href="application.docx"
            download
            className="flex items-center text-lg text-blue-600 underline mb-4"
          >
            <FaDownload className="mr-2" />
            {t('admissionsPage.DownloadApplicationForm')}
          </a>*/}
        </div>
      </div>
    </section>
  )
}

export default Admissions
