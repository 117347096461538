import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useTranslation } from 'react-i18next'

const Gallery = () => {
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  }

  return (
    <section
      className={`bg-gray-50 py-8 md:py-16 ${
        isArabic ? 'text-right arabic-font' : 'text-left english-font'
      }`}
    >
      <div className="container mx-auto px-4">
        <div className="text-center mb-8 md:mb-16">
          <h1 className="text-4xl md:text-5xl font-extrabold text-gray-900 leading-tight mb-2 relative">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-green-700 to-green-400">
              {t('galleryPage.photoGallery')}
            </span>
            <span className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-gradient-to-r from-green-700 to-green-400"></span>
          </h1>
        </div>

        <div className="w-full h-[700px] bg-white shadow-lg rounded-lg p-8 mb-16">
          <Slider {...settings}>
            <div>
              <img
                src="img/g1.webp"
                alt="MS JONA Nursery - Qatar - Gallery 1"
                className="h-[600px] mx-auto rounded-lg shadow-lg object-cover"
              />
            </div>
            <div>
              <img
                src="img/g2.webp"
                alt="MS JONA Nursery - Qatar - Gallery 2"
                className="h-[600px] mx-auto rounded-lg shadow-lg object-cover"
              />
            </div>
            <div>
              <img
                src="img/g3.webp"
                alt="MS JONA Nursery - Qatar - Gallery 3"
                className="h-[600px] mx-auto rounded-lg shadow-lg object-cover"
              />
            </div>
            <div>
              <img
                src="img/g4.webp"
                alt="MS JONA Nursery - Qatar - Gallery 4"
                className="h-[600px] mx-auto rounded-lg shadow-lg object-cover"
              />
            </div>
            <div>
              <img
                src="img/g5.webp"
                alt="MS JONA Nursery - Qatar - Gallery 5"
                className="h-[600px] mx-auto rounded-lg shadow-lg object-cover"
              />
            </div>
            <div>
              <img
                src="img/g6.webp"
                alt="MS JONA Nursery - Qatar - Gallery 6"
                className="h-[600px] mx-auto rounded-lg shadow-lg object-cover"
              />
            </div>
            <div>
              <img
                src="img/g7.webp"
                alt="MS JONA Nursery - Qatar - Gallery 7"
                className="h-[600px] mx-auto rounded-lg shadow-lg object-cover"
              />
            </div>
            <div>
              <img
                src="img/g8.webp"
                alt="MS JONA Nursery - Qatar - Gallery 8"
                className="h-[600px] mx-auto rounded-lg shadow-lg object-cover"
              />
            </div>
            <div>
              <img
                src="img/g9.webp"
                alt="MS JONA Nursery - Qatar - Gallery 9"
                className="h-[600px] mx-auto rounded-lg shadow-lg object-cover"
              />
            </div>
            <div>
              <img
                src="img/g10.webp"
                alt="MS JONA Nursery - Qatar - Gallery 10"
                className="h-[600px] mx-auto rounded-lg shadow-lg object-cover"
              />
            </div>
          </Slider>
        </div>
        <div className="text-center mb-8 md:mb-16">
          <h1 className="text-4xl md:text-5xl font-extrabold text-gray-900 leading-tight mb-2 relative">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-green-700 to-green-400">
              {t('galleryPage.video')}
            </span>
            <span className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-gradient-to-r from-green-700 to-green-400"></span>
          </h1>
        </div>
        <div className="bg-white shadow-lg rounded-lg p-8 mb-8 flex flex-col md:flex-row-reverse items-center">
          <div className="w-full md:w-2/3 mb-8 md:mb-0">
            <iframe
              className="w-full h-64 md:h-[600px] mx-auto rounded-lg shadow-lg"
              src="https://www.youtube.com/embed/cG-gwXGfGcw?si=Nx3ap47f8-t0EdcQ"
              title="YouTube video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div
            className={`w-full md:w-1/3 text-center ${
              isArabic ? 'md:text-right md:pl-8' : 'md:text-left md:pr-8'
            }`}
          >
            <h2 className="text-2xl md:text-3xl font-bold text-gray-900 mb-4">
              {t('galleryPage.dayAtMsJonaNursery')}
            </h2>
            <p className="text-lg text-gray-700">
              {t('galleryPage.description')}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Gallery
