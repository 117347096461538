import React from 'react'
import { useTranslation } from 'react-i18next'

const AboutUs = () => {
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'

  return (
    <section className="bg-gray-50 py-8 md:py-16">
      <div className="container mx-auto px-4">
        <div className="text-center mb-8 md:mb-16">
          <h1
            className={`text-4xl md:text-5xl font-extrabold text-gray-900 leading-tight mb-2 relative ${
              isArabic ? 'arabic-font' : 'english-font'
            }`}
          >
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-green-700 to-green-400">
              {t('about.OurNursery')}
            </span>
            <span className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-gradient-to-r from-green-700 to-green-400"></span>
          </h1>
        </div>

        {/* Vision Section */}
        <div
          className={`bg-white shadow-lg rounded-lg p-8 md:mx-16 mb-16 text-center items-center ${
            isArabic ? 'arabic-font' : 'english-font'
          }`}
        >
          <h2 className="text-2xl md:text-3xl font-bold mb-6">
            {t('about.Vision')}
          </h2>
          <p className="text-lg mb-4">{t('about.VisionDescription')}</p>
          <img
            src="img/vision.jpg"
            alt={isArabic ? 'رؤية MS JONA' : 'MS JONA Nursery vision'}
            className="h-96 mx-auto rounded-lg shadow-lg mt-8 md:mt-0"
          />
        </div>
        <div className="md:flex md:gap-x-16 md:mx-16 mb-8">
          {/* Our Goals Section */}
          <div
            className={`bg-white shadow-lg rounded-lg p-8 mb-8 text-center items-center md:w-1/2 ${
              isArabic ? 'arabic-font' : 'english-font'
            }`}
          >
            <h2 className="text-2xl md:text-3xl font-bold mb-6 text-center">
              {t('about.OurGoals')}
            </h2>
            <p className="text-lg mb-4 text-center">
              {t('about.GoalsDescription')}
            </p>
            <img
              src="img/goal.jpg"
              alt={isArabic ? 'هدف MS JONA' : 'MS JONA Nursery goal'}
              className="w-full h-96 rounded-lg shadow-lg mt-8 md:mt-0"
            />
          </div>

          {/* Our Mission Section */}
          <div
            className={`bg-white shadow-lg rounded-lg p-8 mb-8 text-center items-center md:w-1/2 ${
              isArabic ? 'arabic-font' : 'english-font'
            }`}
          >
            <h2 className="text-2xl md:text-3xl font-bold mb-6 text-center">
              {t('about.OurMission')}
            </h2>
            <p className="text-lg mb-4 text-center">
              {t('about.MissionDescription')}
            </p>
            <img
              src="img/mission.jpg"
              alt={isArabic ? 'مهمة MS JONA' : 'MS JONA Nursery mission'}
              className="w-full h-96 rounded-lg shadow-lg mt-8 md:mt-0"
            />
          </div>
        </div>

        {/* Message from the Principal Section */}
        <div
          className={`flex flex-col md:flex-row bg-white shadow-lg rounded-lg p-8 mb-8 text-center items-center md:mx-16 ${
            isArabic ? 'arabic-font' : 'english-font'
          }`}
        >
          <div className="md:w-1/2">
            <h2 className="text-2xl md:text-3xl font-bold mb-6 text-center">
              {t('about.MessageFromPrincipal')}
            </h2>
            <p className="text-lg mb-4">
              {t('about.MessageFromPrincipalDescription1')}
            </p>
            <p className="text-lg mb-4">
              {t('about.MessageFromPrincipalDescription2')}
            </p>
            <p className="text-lg mb-4">
              {t('about.MessageFromPrincipalDescription3')}
            </p>
            <p className="text-lg mb-4">
              {t('about.MessageFromPrincipalDescription4')}
            </p>
            <p className="text-lg mb-4">
              {t('about.MessageFromPrincipalDescription5')}
            </p>
            <p className="text-lg mb-4">
              {t('about.MessageFromPrincipalDescription6')}
            </p>
            <p className="text-lg mb-4">
              {t('about.MessageFromPrincipalDescription7')}
            </p>
            <p className="text-lg font-bold mt-8 text-center">
              {t('about.Sincerely')}
            </p>
            <p className="text-lg font-bold text-center">
              {t('about.Principal')}
            </p>
            <p className="text-lg font-bold text-center">
              {t('about.Position')}
            </p>
          </div>
          <div
            className={`md:w-1/2 ${
              isArabic ? 'md:text-right md:pr-8' : 'md:text-left md:pl-8'
            }`}
          >
            <img
              src="img/about1.webp"
              alt={isArabic ? 'المدير' : 'Principal'}
              className="w-full rounded-lg shadow-lg mt-8 md:mt-0"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUs
