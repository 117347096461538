import React from 'react'
import { useTranslation } from 'react-i18next'

const Features = () => {
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'

  return (
    <section
      className={`bg-gray-50 py-8 md:py-16 ${
        isArabic ? 'arabic-font' : 'english-font'
      }`}
    >
      <div className="container mx-auto px-4">
        <div className="text-center mb-8 md:mb-16">
          <h1 className="text-4xl md:text-5xl font-extrabold text-gray-900 leading-tight mb-2 relative">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-green-700 to-green-400">
              {t('features.our_features')}
            </span>
            <span className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-gradient-to-r from-green-700 to-green-400"></span>
          </h1>
        </div>

        {/* Grab Interest Section */}
        <div className="bg-white shadow-lg rounded-lg p-8 mb-8 flex flex-col md:flex-row items-center">
          <div className="md:w-1/3 md:mr-8 md:mb-0">
            <img
              src="img/f1.jpeg"
              alt={t('features.grab_interest')}
              className="rounded-lg shadow-lg mb-8 md:mb-0"
            />
          </div>
          <div className="md:w-2/3 mb-6 md:mb-0">
            <h3
              className={`text-2xl md:text-3xl ${
                isArabic ? 'arabic-font' : 'english-font'
              } font-bold mb-4`}
            >
              {t('features.grab_interest')}
            </h3>
            <p className="text-gray-700 text-lg">
              {t('features.grab_interest_description')}
            </p>
          </div>
        </div>

        {/* Generate Excitement Section */}
        <div className="bg-white shadow-lg rounded-lg p-8 mb-8 flex flex-col md:flex-row items-center">
          <div className="my-auto md:w-1/3 md:mr-8">
            <img
              src="img/f2.jpeg"
              alt={t('features.generate_excitement')}
              className="rounded-lg shadow-lg mb-8 md:mb-0"
            />
          </div>
          <div className="md:w-2/3 mb-6 md:mb-0">
            <h3
              className={`text-2xl md:text-3xl ${
                isArabic ? 'arabic-font' : 'english-font'
              } font-bold mb-4`}
            >
              {t('features.generate_excitement')}
            </h3>
            <p className="text-gray-700 text-lg mb-4">
              {t('features.generate_excitement_description')}
            </p>
            <ul className="list-disc pl-8 text-gray-700">
              <li className="mb-2">
                <strong>{t('features.phonics_club_title')}:</strong>{' '}
                {t('features.phonics_club_description')}
              </li>
              <li className="mb-2">
                <strong>{t('features.ball_skills_title')}:</strong>{' '}
                {t('features.ball_skills_description')}
              </li>
              <li className="mb-2">
                <strong>{t('features.eyfs_curriculum_title')}:</strong>{' '}
                {t('features.eyfs_curriculum_description')}
              </li>
              <li className="mb-2">
                <strong>{t('features.best_practice_curriculum_title')}:</strong>{' '}
                {t('features.best_practice_curriculum_description')}
              </li>
              <li className="mb-2">
                <strong>{t('features.school_readiness_title')}:</strong>{' '}
                {t('features.school_readiness_description')}
              </li>
            </ul>
            <p className="text-gray-700 text-lg">
              {t('features.enroll_today')}
            </p>
          </div>
        </div>

        {/* Close the Deal Section */}
        <div className="bg-white shadow-lg rounded-lg p-8 mb-8 flex flex-col md:flex-row items-center">
          <div className="md:w-1/3 md:mr-8 md:mb-0">
            <img
              src="img/f3.jpeg"
              alt={t('features.close_the_deal')}
              className="rounded-lg shadow-lg mb-8 md:mb-0"
            />
          </div>
          <div className="md:w-2/3 mb-6 md:mb-0">
            <h3
              className={`text-2xl md:text-3xl ${
                isArabic ? 'arabic-font' : 'english-font'
              } font-bold mb-4`}
            >
              {t('features.close_the_deal')}
            </h3>
            <p className="text-gray-700 text-lg mb-4">
              {t('features.experienced_staff')}
            </p>
            <h4 className="text-xl font-bold mb-2">
              {t('features.benefits_signing_up')}
            </h4>
            <ul className="list-disc pl-8 text-gray-700">
              <li className="mb-2">
                <strong>{t('features.high_quality_education')}:</strong>{' '}
                {t('features.high_quality_education_description')}
              </li>
              <li className="mb-2">
                <strong>{t('features.experienced_staff')}:</strong>{' '}
                {t('features.experienced_staff_description')}
              </li>
              <li className="mb-2">
                <strong>{t('features.safe_environment')}:</strong>{' '}
                {t('features.safe_environment_description')}
              </li>
              <li className="mb-2">
                <strong>{t('features.diverse_learning')}:</strong>{' '}
                {t('features.diverse_learning_description')}
              </li>
              <li className="mb-2">
                <strong>{t('features.strong_community')}:</strong>{' '}
                {t('features.strong_community_description')}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Features
