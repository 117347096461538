import React from 'react'

const Hero = () => {
  return (
    <section className="flex items-center justify-center h-[300px] md:h-[700px] bg-white-200">
      <div className="relative flex items-center justify-center w-full">
        <img
          src="img/logo.png"
          alt="Ms. Jona Nursery"
          className="w-[400px] md:w-[700px] transition-transform duration-500 hover:scale-110 animate-bounce"
        />
      </div>
    </section>
  )
}

export default Hero
