import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Header = () => {
  const { t, i18n } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
  }
  const isArabic = i18n.language === 'ar'

  useEffect(() => {
    document.documentElement.dir = i18n.language === 'ar' ? 'rtl' : 'ltr'
  }, [i18n.language])

  return (
    <header className="bg-white shadow-lg">
      <div className="container mx-auto px-4 py-3 md:py-6 flex justify-between items-center relative">
        <div className="flex items-center">
          <img
            src="img/logo.png"
            alt="Ms. Jona Nursery Logo"
            className="h-12 mr-4"
          />
        </div>
        <div className="flex items-center">
          <button
            className="block md:hidden focus:outline-none mr-4"
            onClick={toggleMenu}
          >
            <svg
              className="fill-current text-gray-700"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M20 2.5a.5.5 0 0 1-.5.5H.5a.5.5 0 1 1 0-1h19a.5.5 0 0 1 .5.5zM20 8a.5.5 0 0 1-.5.5H.5a.5.5 0 1 1 0-1h19a.5.5.5 0 0 1 .5.5zM20 13.5a.5.5.5 0 0 1-.5.5H.5a.5.5 0 1 1 0-1h19a.5.5.5 0 0 1 .5.5z"
              />
            </svg>
          </button>
        </div>
        <nav
          className={`${
            isOpen ? 'absolute' : 'hidden'
          } top-full left-0 right-0 bg-white border-t border-gray-200 md:relative md:flex md:items-center md:bg-transparent md:border-none z-20`}
        >
          <ul
            className={`md:flex md:items-center md:space-x-4 text-lg font-semibold ${
              isArabic ? 'arabic-font' : 'english-font'
            }`}
          >
            <NavItem
              to="/"
              currentPath={location.pathname}
              onClick={toggleMenu}
            >
              {t('home')}
            </NavItem>
            <NavItem
              to="/about-us"
              currentPath={location.pathname}
              onClick={toggleMenu}
            >
              {t('about_us')}
            </NavItem>
            <NavItem
              to="/team"
              currentPath={location.pathname}
              onClick={toggleMenu}
            >
              {t('team')}
            </NavItem>
            <NavItem
              to="/admissions"
              currentPath={location.pathname}
              onClick={toggleMenu}
            >
              {t('admissions')}
            </NavItem>
            <NavItem
              to="/gallery"
              currentPath={location.pathname}
              onClick={toggleMenu}
            >
              {t('gallery')}
            </NavItem>
            <NavItem
              to="/contact-us"
              currentPath={location.pathname}
              onClick={toggleMenu}
            >
              {t('contact_us')}
            </NavItem>
          </ul>
          <div className="relative">
            <select
              value={i18n.language}
              onChange={(e) => changeLanguage(e.target.value)}
              className="appearance-none bg-white border rounded mx-4 my-2 md:my-0 py-2 pl-10 pr-4 text-gray-700 focus:outline-none border-green-600 focus:border-black"
              style={{
                backgroundImage: `url(/img/${
                  i18n.language === 'ar' ? 'qa' : 'sh'
                }.png)`,
                backgroundSize: '20px 20px',
                backgroundPosition: 'left 10px center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <option
                value="en"
                style={{ backgroundImage: "url('/img/flags/usa.png')" }}
              >
                English
              </option>
              <option
                value="ar"
                style={{ backgroundImage: "url('/img/flags/qatar.png')" }}
              >
                العربية
              </option>
            </select>
          </div>
        </nav>
      </div>
    </header>
  )
}

const NavItem = ({ to, currentPath, onClick, children }) => {
  const isActive = to === currentPath

  return (
    <li>
      <Link
        to={to}
        className={`text-gray-700 block py-2 px-4 ${
          isActive
            ? 'bg-green-600 font-bold text-white rounded hover:text-gray-300'
            : 'hover:text-green-600'
        }`}
        onClick={onClick}
      >
        {children}
      </Link>
    </li>
  )
}

export default Header
